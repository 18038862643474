import React, { Ref } from 'react';
import { Tooltip } from 'wix-ui-tpa/cssVars';
import { st, classes } from './Breadcrumbs.st.css';
import { Chevron } from '../Chevron';
import { FCWithChildren } from '@wix/challenges-web-library';

export interface IBreadcrumbLink {
  text: React.ReactNode;
  onClick?(): void;
  btnRef?: Ref<HTMLButtonElement>;
}

export interface IBreadcrumbs {
  className?: string;
}

export type ReactFunctionalWithStaticProps<P, S> = FCWithChildren<P> & S;

const BreadcrumbsLink: FCWithChildren<IBreadcrumbLink> = (props) => {
  const textRef = React.useRef<HTMLSpanElement>();
  const [isElementTruncated, setIsElementTruncated] =
    React.useState<boolean>(false);

  React.useEffect(() => {
    setIsElementTruncated(
      textRef?.current?.offsetWidth < textRef?.current?.scrollWidth,
    );
  }, [textRef]);

  return (
    <li className={classes.breadcrumbsItem}>
      <Tooltip
        disabled={!isElementTruncated}
        content={props.text}
        appendTo="window"
        maxWidth={250}
      >
        {props.onClick ? (
          <button
            className={classes.breadcrumbsLink}
            onClick={props.onClick}
            ref={props.btnRef}
          >
            <span ref={textRef} className={classes.breadcrumbsText}>
              {props.text}
            </span>
          </button>
        ) : (
          <span ref={textRef} className={classes.breadcrumbsText}>
            {props.text}
          </span>
        )}
      </Tooltip>
    </li>
  );
};

const BreadcrumbsSeparator: FCWithChildren = () => {
  return (
    <li className={classes.breadcrumbsItem}>
      <span className={classes.breadcrumbSeparator}>
        <Chevron />
      </span>
    </li>
  );
};

export const Breadcrumbs: ReactFunctionalWithStaticProps<
  IBreadcrumbs,
  { Link: typeof BreadcrumbsLink; Separator: typeof BreadcrumbsSeparator }
> = (props) => {
  return (
    <ol className={st(classes.root, {}, props.className)}>{props.children}</ol>
  );
};

Breadcrumbs.Link = BreadcrumbsLink;
Breadcrumbs.Separator = BreadcrumbsSeparator;
